import React from "react";
import Head from "next/head";
import { StoreInterface } from "@/assets/interfaces";

interface StoreFrontHeadProps {
  store: StoreInterface;
}

const StoreFrontHead: React.FC<StoreFrontHeadProps> = ({ store }) => {
  // Use store logo as favicon if available, otherwise fallback to default
  const faviconUrl = store?.logo || "/favicon.png";

  return (
    <Head>
      <link rel="icon" href={faviconUrl} type="image/png" />
    </Head>
  );
};

export default StoreFrontHead;
